import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { gsap, ScrollTrigger } from 'gsap/all'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

gsap.registerPlugin(ScrollTrigger)

/**
 * Base
 */
const cursor = {
    x: 0,
    y: 0
}

window.addEventListener('mousemove', function(event) {
    cursor.x = event.clientX / window.innerWidth - 0.5
    cursor.y = event.clientY / window.innerHeight - 0.5
})

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


// const axesHelper = new THREE.AxesHelper( 2 ); 
// scene.add( axesHelper );

// Raycaster
const raycaster = new THREE.Raycaster()

// Groupe de bâtiments
const buildings = new THREE.Group()
scene.add(buildings)

//Models
let isMountainLoaded = false
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
dracoLoader.setDecoderConfig({type:'js'})
dracoLoader.preload()

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

gltfLoader.load(
    '/models/mountain/mountain.gltf',
    (gltf) =>
    {
        const mountain = gltf.scene
        mountain.name = "tahiti"
        scene.add(mountain)
        mountain.scale.set(1.4,1.4,1.4)
        mountain.rotation.y = 45

        mountain.position.y = 0.38


        isMountainLoaded = true
    }
)
gltfLoader.load(
    '/models/bungalow/bungalow.gltf',
    (gltf) =>
    {
        const bungalow = gltf.scene
        bungalow.name = "creative"
        buildings.add(bungalow)
        bungalow.scale.set(0.051,0.051,0.051)
        bungalow.rotation.y = -1.2

        bungalow.position.z = 1.65
        bungalow.position.x = 0.5
        bungalow.position.y = 0

    }
)

gltfLoader.load(
    '/models/house/house.gltf',
    (gltf) =>
    {
        const house = gltf.scene
        house.name = "design"
        buildings.add(house)
        house.scale.set(0.049,0.049,0.049)
        house.rotation.y =90

        house.position.z = 0.9
        house.position.x = -1.7
        house.position.y = 0.11
    }
)

gltfLoader.load(
    '/models/roulotte/roulotte.gltf',
    (gltf) =>
    {
        const roulotte = gltf.scene
        roulotte.name = "dev"
        buildings.add(roulotte)
        roulotte.scale.set(0.04,0.04,0.04)
        // roulotte.rotation.y =-0.2

        roulotte.position.z = -0.8
        roulotte.position.x = 1.99
        roulotte.position.y = 0.12
    }
)

gltfLoader.load(
    '/models/transat/transat.gltf',
    (gltf) =>
    {
        const transat = gltf.scene
        transat.name = "me"
        buildings.add(transat)
        transat.scale.set(0.04, 0.04, 0.04)
        transat.position.y = .09
        transat.position.z = .72
        transat.position.x = .8

        transat.rotation.y = -2 * Math.PI / 3
    }
)


/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(100, 100),
    new THREE.MeshStandardMaterial({
        color: '#75DDFA',
        metalness: 0,
        roughness: 0.5
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
scene.add(floor)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight('#ee9b00', 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(5, 5, 5)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
// camera.position.set(1.51, 2.04, 2.58)
// camera.rotation.set(-.67, .32, .25)

camera.position.set(-2.58, .34, .92)
camera.rotation.set(.003, -1.08, .002)


scene.add(camera)
scene.fog = new THREE.Fog(0x2ca7e8, 1, 15)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.75, 0)
controls.enableDamping = true
controls.maxDistance = 3.43
controls.autoRotateSpeed = -2.0
controls.autoRotate = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))



/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    if(camera.position.y < .2) {
        camera.position.y = .2
    }

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

/**
 * Au scroll, animer la caméra
 */

// DESIGN
const designTimeline = gsap.timeline({
    scrollTrigger: {
        trigger: '#design',
        end: 'bottom top',
        scrub: .3,
        onUpdate: () => controls.autoRotate = false
    }
})
designTimeline.to(
    camera.position,
    {
        duration: 1,
        x: -2.58,
        y: .34,
        z: .92
    }
)
designTimeline.to(
    camera.rotation,
    {
        duration: 1,
        x: .0032,
        y: -1.078,
        z: .002
    }
)

// CREATIVE
const creativeTimeline = gsap.timeline({
    scrollTrigger: {
        trigger: '#creative',
        end: 'bottom top',
        scrub: .3,
        onUpdate: () => controls.autoRotate = false
    }
})
creativeTimeline
    .to(
        camera.position,
        {
            duration: 1,
            x: -.244,
            y: 0.218,
            z: 2.509
        }
    )
    .to(
        camera.rotation,
        {
            duration: 1,
            x: -.095,
            y: 0.67,
            z: -5.059
        }
    )

// DEV
const devTimeline = gsap.timeline({
    scrollTrigger: {
        trigger: '#dev',
        end: 'bottom top',
        scrub: .3,
        onUpdate: () => controls.autoRotate = false
    }
})
devTimeline.to(
    camera.position,
    {
        duration: 1,
        x: 2.69,
        y: 0.27,
        z: -0.59
    }
)
devTimeline.to(
    camera.rotation,
    {
        duration: 1,
        x: -2.63,
        y: 1.27,
        z: 2.63
    }
)

// ME
const meTimeline = gsap.timeline({
    scrollTrigger: {
        trigger: '#me',
        end: 'bottom top',
        scrub: .3,
        onUpdate: () => controls.autoRotate = false
    }
})
meTimeline
    .to(
        camera.position,
        {
            duration: 1,
            x: 0.533,
            y: 0.247,
            z: 1.236
        }
    )
    .to(
        camera.rotation,
        {
            duration: 1,
            x: -0.041,
            y: 0.755,
            z: 0.028
        }
    )


document.addEventListener('keydown', (event) => {
    if(event.code == 'KeyL') {
        console.log(`
            posX: ${camera.position.x}
            posY: ${camera.position.y}
            posZ: ${camera.position.z}
            rotX: ${camera.rotation.x}
            rotY: ${camera.rotation.y}
            rotZ: ${camera.rotation.z}
        `)
    } else if(event.code == 'Escape') {
        const activeModal = document.querySelector('.modal.active')
        if(activeModal) {
            exitModalAnimation(activeModal)
        }
    }
})

// Gestion du slider
document.querySelectorAll('.with-slider').forEach(section => {
    const leftArrow = section.querySelector('.chevron.cursor.left')
    const rightArrow = section.querySelector('.chevron.cursor.right')
    const slider = section.querySelector('.slider')
    
    const numberOfSlides = slider.querySelectorAll('.slide').length
    let currentSlide = 0
    

    leftArrow.addEventListener('click', (event) => {
        const slideWidth = 100 * slider.querySelector('.slide').offsetWidth / sizes.width // convert px to vw
        const step = slideWidth + 100 // width defined in css for each slide + margin

        event.preventDefault()
        if(currentSlide == 0) return
        
        currentSlide = (currentSlide - 1) % numberOfSlides

        slider.style.transform = `translateX(${ -currentSlide * step }vw)`
    })

    rightArrow.addEventListener('click', (event) => {
    const slideWidth = 100 * slider.querySelector('.slide').offsetWidth / sizes.width // convert px to vw
    const step = slideWidth + 100 // width defined in css for each slide + margin

        event.preventDefault()
        currentSlide = (currentSlide + 1) % numberOfSlides

        slider.style.transform = `translateX(${ -currentSlide * step }vw)`
    })

})

document.addEventListener('scroll', (event) => {
    if(document.documentElement.scrollTop == 0) {
        controls.autoRotate = true
    }
})

